import { Link } from "gatsby"
import React from "react"

const Drawer = ({ active, children, header, onClose }) => {
    if (!active) {
        return null;
    }
    return <div className="drawer-wrapper">
        <div className="drawer-backdrop fade in"></div>
        <div className="drawer animated fadeInLeft faster">
            <div className="drawer-dialog">
                <div className="drawer-content">
                    <div className="drawer-header">
                        <div>
                            {header}
                        </div>
                        <a onClick={onClose} className="close"></a>

                    </div>

                    <div className="drawer-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>

    </div>
}


export default Drawer
