import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import "./layout.css"
import "./animate.css"
import Navbar from "./nav"
import Logo from "./logo"
import Drawer from "./drawer"

const Layout = ({ children }) => {
  const [active, setActive] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          teamviewerLink
        }
      }
    }
  `)

  return (
    <>
      <Drawer active={active} onClose={() => setActive(false)}>
        <Navbar vertical onMenuSelected={() => setActive(false)} />
      </Drawer>
      <Helmet>
      </Helmet>
      <div className="layout-container">
        <div className="logo-container" >
          <Logo style={{ height: "50%", width: "100%", flex: "1 1" }} />
        </div>
        <main className="layout-content">
          <Navbar onOpen={() => setActive(true)} teamviewer={data.site.siteMetadata.teamviewerLink} />
          <div className="content-container">
            {children}
          </div>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
