import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"

const Navbar = ({ vertical, onOpen, teamviewer, onMenuSelected }) => (
    <div className="nav-container">
        {!vertical && <div className="nav-logo-container" onClick={onOpen}>
            <Logo style={{ height: 40, width: 66 }} />
        </div>}
        <nav className={`main-nav${vertical ? " vertical" : ""}`}>
            <Link style={{ padding: "20px 40px", position: "relative" }} activeClassName="active" to="/" onClick={onMenuSelected}>Home</Link>
            {/* <Link style={{ padding: "20px 40px", position: "relative" }} activeClassName="active" to="/jobs" onClick={onMenuSelected}>Stellenausschreibungen</Link> */}
            <Link style={{ padding: "20px 40px", position: "relative" }} activeClassName="active" to="/impressum" onClick={onMenuSelected}>Impressum</Link>
        </nav>
        <div className="nav-sub"></div>
        <div className="tv-container">
            <a style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }} href={teamviewer} target="__blank">
                <img style={{ margin: 0 }} src="../../logo-teamviewer-blue.webp" />
            </a>
        </div>
    </div>
)
export default Navbar