import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = ({ style }) => {
   return <svg className="logo" x="0px" y="0px" viewBox="0 0 189 299" style={{ fill: "transparent", stroke: "white", ...style }}>
        <path className="st0" d="M127,269.4c-6.4,9.3-14.4,16.5-24,21.6c-9.6,5.1-19.7,7.6-30.3,7.6c-10,0-19.4-2.2-28-6.5
        c-8.6-4.3-16.1-10.1-22.4-17.3c-6.3-7.2-11.2-15.6-14.8-25.1C3.9,240.2,2,230.3,2,220c0-8.4,1.2-16.4,3.6-24.2
        c2.4-7.8,5.6-15.2,9.6-22.1c4-7,8.7-13.6,14-19.8c5.3-6.3,10.9-12.3,16.7-18.2c-7-10.6-12.9-22.4-17.8-35.3
        c-4.9-13-7.3-25.8-7.3-38.6c0-8.6,1.5-16.6,4.4-24c2.9-7.4,7-13.7,12.3-19c5.3-5.3,11.6-9.5,19-12.5C63.9,3.1,72.1,1.5,81,1.5
        c17.5,0,32,4.9,43.2,14.6c11.3,9.8,16.9,23.8,16.9,42.2c0,8.6-1.4,16.8-4.2,24.4c-2.8,7.7-6.3,14.9-10.7,21.7
        c-4.3,6.8-9.3,13.3-14.8,19.4c-5.6,6.1-11.3,12-17.1,17.5l41.4,68.5l25.9-36.8l26.7,21.7L155,241.8l33.4,52.6H142L127,269.4z
            M66,168.2c-7.2,7.5-13,15-17.3,22.7c-4.3,7.6-6.5,16.7-6.5,27.2c0,5.5,0.8,11,2.5,16.2c1.7,5.3,4,10,7.1,14.1
        c3.1,4.2,6.7,7.5,11.1,10c4.3,2.5,9.3,3.7,14.8,3.7c7.2,0,13.4-2,18.4-6c5-4,9.5-9.2,13.4-15.6L66,168.2z M76.8,110.2
        c3.6-3.6,7.2-7.3,10.7-11.1c3.5-3.8,6.7-7.7,9.6-11.9c2.9-4.2,5.3-8.6,7.1-13.4c1.8-4.7,2.7-9.9,2.7-15.5c0-7.2-2-13.2-5.8-18
        c-3.9-4.7-9.6-7.1-17.1-7.1c-7.2,0-13.2,2.9-17.8,8.8s-6.9,12.1-6.9,18.8c0,8.9,1.9,17.4,5.8,25.5C69,94.4,72.9,102.4,76.8,110.2z"
        />
    </svg>
}

export default Logo
