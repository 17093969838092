module.exports = [{
      plugin: require('C:/Development/Torschitz-Schanes/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#001529","theme_color":"#001529","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('C:/Development/Torschitz-Schanes/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Development\\Torschitz-Schanes\\src\\components\\layout.js"},
    },{
      plugin: require('C:/Development/Torschitz-Schanes/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
